import React from 'react'

const Benefits = () => {
  return (
    <section className='py-4'>
      <div className='container'>
        <div className='col-12 col-lg-8 px-0 pt-5'>
          <h3 className='fs-xl-26'>Your company also deserves a free checking account</h3>
          <p className='mr-xl-3'>The corporate checking account is 100% digital and free. With the account, you will be able to make all your company transactions through internet banking, free of charges and fees.</p>
          <h4 className='pb-3 fs-md-26 fs-lg-28'>Designed to make your business administration easier</h4>
          <ul className='mb-5'>
            <li>Up to 100 free transfers monthly</li>
            <li>MasterCard debit card for purchases</li>
            <li>Agreement with the main card machine companies, for receiving your sales – be it credit or debit</li>
            <li>Account statement by period - PDF, OFX and CSV</li>
            <li>Invoice and ticket payments;</li>
            <li>Up to 100 free invoices per month</li>
            <li>Automatic payroll processing and execution</li>
            <li>Customized access per user</li>
            <li>Multiple account access</li>
            <li>Interpg: Receive through QR Code, free of charges</li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Benefits
