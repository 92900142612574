import React from 'react'
import Badge from 'components/UI/Badge'

const HaveAnotherCardMachine = () => {
  return (
    <section className='py-5 bg-gray--100'>
      <div className='container'>
        <div className='col-12'>
          <h4 className=''>Have another card machine?<br />Change your bank address.</h4>
          <ul className='list-unstyled'>
            <li className='d-flex align-items-center mb-4'>
              <Badge size='large' icon='zero' />
              <p className='fs-20'>Free of charges</p>
            </li>
            <li className='d-flex align-items-center mb-4'>
              <Badge size='large' icon='transfer' />
              <p className='fs-20'>Agreement with the main card machine companies</p>
            </li>
            <li className='d-flex align-items-center'>
              <Badge size='large' icon='hand' />
              <p className='fs-20'>Advanced payment possibility</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default HaveAnotherCardMachine
