import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import Hero from './sections/header/_hero'
import Benefits from './sections/benefits/_benefits'
import HaveAnotherCardMachine from './sections/have-another-card-machine/_have-another-card-machine'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

const CorporateAccount = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <Benefits />
        <HaveAnotherCardMachine />
      </Layout>
    </Wrapper>
  )
}

export default CorporateAccount
